@font-face {
  font-family: Brandon Grotesque Light;
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Light'),
    url('https://cdn.botpenguin.com/assets/fonts/brandon-grotesque-light-58a8a4b38001d.woff') format('woff');
}

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  font-family: Brandon Grotesque Light, sans-serif !important;
  font-weight: bolder !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-theme-color: #118cfc;
}

html {
  scroll-behavior: smooth;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-theme-color);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-theme-color);
}


.Mui-focused {
  border: none !important;
}

body .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
  box-shadow: none !important;
}

body .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

body .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

body .MuiInputBase-input {
  color: #404040;
}

.react-calendar__tile--now {
  background: var(--primary-theme-color) !important;
  opacity: 0.5;
}

.react-calendar__tile--active {
  background: var(--primary-theme-color) !important;
  opacity: 1;
}

video {
  width: 100% !important;
}

abbr {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.MuiListItem-root.Mui-disabled {
  opacity: 0.2 !important;
}

.loader {
  background-color: #B3B3B3;
  height: 100dvh;
  text-align: center;

  img {
    width: 54px;
    margin-top: 48dvh;
  }
}

.loader-pagination {
  display: flex;
  justify-content: center;

  img {
    width: 54px;
  }
}